import React from "react"
import Base from "../Base"
import "../styles/about.scss"

const Investors = () => (
  <Base title="Investors">
    <header className="content-header">
      <h1>Learn more about investing in Pine Financial</h1>
    </header>
    <main>
      <div className="frame-wrapper">
        <iframe
          src="/slide_deck.pdf"
          title="Investor pitch"
          className="pitch-pdf"
          frameBorder="0"
        />
      </div>
      <h3 className="text-center">
        Download our pitch deck{" "}
        <a href="/slide-deck.pdf" download>
          here
        </a>
      </h3>
    </main>
  </Base>
)

export default Investors
